
  import { Action, Getter } from "vuex-class";
  import { Component, Vue } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";
  import { Seller } from "@/store/modules/seller.store";

  @Component({
    components: {
      BidCard: () => import("./parts/bid/card.vue"),
      PageTitle: () => import("./parts/general/page-title.vue"),
      PageToggle: () => import("./parts/general/page-toggle.vue"),
      PageSlider: () => import("./parts/general/page-slider.vue"),
    },
  })
  export default class PageSellerBids extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("bid/all") bids!: Bid[];
    @Getter("seller/all") sellers!: Seller[];

    @Action("bid/index") indexBids!: (payload: { query?: string; activity_id: number; after?: Array<string>; seller_id?: number | boolean }) => Promise<Bid[]>;

    tab = "new";

    get tabs() {
      let tabs = [
        { label: "Lopend", value: "new", count: this.newBids ? this.newBids.length : undefined },
        { label: "Afgekeurd", value: "due" },
      ];

      if (this.bids.some((bid) => bid.status === "APPROVED")) {
        tabs = [{ label: "Goedgekeurd", value: "approved" }, ...tabs];
      }

      return tabs;
    }

    formatDate = formatDate;

    loading = false;

    selected: Bid | null = null;

    async mounted() {
      this.loading = true;

      let sellerId: boolean | number = false;
      if (!this.me.seller_id) {
        sellerId = this.sellers[0].id;
      }

      await this.indexBids({ activity_id: Number(this.$route.params.id), seller_id: sellerId });

      if (this.approvedBids && this.approvedBids?.length > 0) {
        this.tab = "approved";
      }

      this.loading = false;
    }

    get tagName() {
      return this.tabs.find((t) => t.value === this.tab)?.label;
    }

    get filteredBids() {
      if (this.tab === "new") {
        return this.newBids ? this.newBids : [];
      }

      if (this.tab === "due") {
        return this.expiredBids ? this.expiredBids : [];
      }

      if (this.tab === "approved") {
        return this.approvedBids ? this.approvedBids : [];
      }

      return [];
    }

    get newBids() {
      // get bids where created_at is not 7 days expired
      return this.bids
        ? this.bids
            .map((bid) => {
              const date = new Date(bid.created_at);
              if (!date) {
                return { ...bid, expiration_days: 0 };
              }
              const now = new Date();
              const diff = now.getTime() - date.getTime();
              const expirationDate = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);

              const days = diff / (1000 * 3600 * 24);

              return { ...bid, expiration_days: days, expiration_date: expirationDate.toISOString() };
            })
            .filter((bid) => {
              return bid.expiration_days < 7 && bid.status !== "REJECTED" && bid.status !== "APPROVED";
            })
        : undefined;
    }

    get expiredBids() {
      // get bids where created_at is 7 days expired
      return this.bids
        ? this.bids
            .map((bid) => {
              const date = new Date(bid.created_at);
              if (!date) {
                return { ...bid, expiration_days: 0 };
              }
              const now = new Date();
              const diff = now.getTime() - date.getTime();
              const expirationDate = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);

              const days = diff / (1000 * 3600 * 24);

              return { ...bid, expiration_days: days, expiration_date: expirationDate.toISOString() };
            })
            .filter((bid) => {
              return (bid.expiration_days >= 7 || bid.status === "REJECTED") && bid.status !== "APPROVED";
            })
        : undefined;
    }

    get approvedBids() {
      return this.bids ? this.bids.filter((bid) => bid.status === "APPROVED") : undefined;
    }
  }
